export const titles = {
	widgets: {
		1: "Checkbox",
		2: "Connect",
		4: "Header",
		5: "Block",
		6: "Image",
		7: "List",
		8: "Radio",
		9: "Text Block",
		10: "Video",
		11: "Comparison"
	}
};
